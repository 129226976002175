<template>
  <div id="user-view">
    <v-row>
      <v-col v-if="employeeData.id" cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            employeeName: `${employeeData.firstName} ${employeeData.lastName}`,
          }"
        />
        <employee-bio-panel :loading="loading" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(`${tab.title}`) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items id="user-tabs-content" :key="employeeData.id" v-model="userTab" class="mt-5 pa-1" touchless>
          <v-tab-item>
            <employee-tab-files v-if="userTab === 0 && employeeData.id" :key="componentTabKey"></employee-tab-files>
          </v-tab-item>

          <v-tab-item>
            <employee-tab-reminders v-if="userTab === 1" @refetch-data="fetchEmployeeData" />
          </v-tab-item>

          <v-tab-item>
            <employee-tab-position-assignees-table :key="componentTabKey" @refetch-data="fetchEmployeeData" />
          </v-tab-item>

          <v-tab-item>
            <employee-timesheets-table :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <employee-tab-custom-fields :key="componentTabKey" />
          </v-tab-item>

          <v-tab-item>
            <employee-tab-address :loading="loading" @refetch-data="fetchEmployeeData" />
          </v-tab-item>

          <v-tab-item>
            <employee-tab-internal-notes :key="componentTabKey" @refetch-data="fetchEmployeeData" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch } from '@vue/composition-api'
import {
  mdiFileAccountOutline,
  mdiBellOutline,
  mdiBriefcaseOutline,
  mdiAccountDetailsOutline,
  mdiMapMarkerOutline,
  mdiCommentTextOutline,
  mdiCalendarOutline,
} from '@mdi/js'
import { useActions, useState, useMutations } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import EmployeeBioPanel from './employee-bio-panel/EmployeeBioPanel.vue'
import EmployeeTabInternalNotes from './employee-tab-internal-notes/EmployeeTabInternalNotes.vue'
import EmployeeTabAddress from './employee-tab-address/EmployeeTabAddress.vue'
import EmployeeTabFiles from './employee-tab-files/EmployeeTabFiles.vue'
import EmployeeTabReminders from './employee-tab-reminders/EmployeeTabReminders.vue'
import EmployeeTabCustomFields from './employee-tab-custom-fields/EmployeeTabCustomFields.vue'
import EmployeeTabPositionAssigneesTable from './employee-tab-position-assignees/EmployeeTabPositionAssigneesTable.vue'
import EmployeeTimesheetsTable from './employee-tab-timesheets/EmployeeTimesheetsTable.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    EmployeeBioPanel,
    EmployeeTabInternalNotes,
    EmployeeTabAddress,
    EmployeeTabFiles,
    EmployeeTabReminders,
    EmployeeTabCustomFields,
    EmployeeTimesheetsTable,
    EmployeeTabPositionAssigneesTable,
  },
  setup() {
    const tabs = [
      { icon: mdiFileAccountOutline, title: 'files' },
      { icon: mdiBellOutline, title: 'reminders' },
      { icon: mdiBriefcaseOutline, title: 'positions' },
      { icon: mdiCalendarOutline, title: 'timesheet' },
      { icon: mdiAccountDetailsOutline, title: 'customFields' },
      { icon: mdiMapMarkerOutline, title: 'address' },
      { icon: mdiCommentTextOutline, title: 'internalNotes' },
    ]

    const userTab = ref(null)
    const loading = ref(false)

    const { getEmployeeDetails } = useActions('staff', ['getEmployeeDetails'])
    const { employeeData } = useState('staff', ['employeeData'])
    const { SET_EMPLOYEE_DATA } = useMutations('staff', ['SET_EMPLOYEE_DATA'])

    const componentTabKey = ref(0)
    const forceRerenderTab = () => {
      componentTabKey.value += 1
    }

    const fetchEmployeeData = async () => {
      loading.value = true
      await getEmployeeDetails(router.currentRoute.params.id)
      loading.value = false
      forceRerenderTab()
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchEmployeeData()
      },
    )

    onMounted(async () => {
      await fetchEmployeeData()
    })

    onUnmounted(() => {
      SET_EMPLOYEE_DATA({
        files: [],
        languages: [],
      })
    })

    return {
      tabs,
      userTab,
      employeeData,
      loading,
      componentTabKey,

      fetchEmployeeData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
