<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(employeeData.employeeIds, employeeData.id, loading, {
                name: 'apps-employee-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(employeeData.employeeIds, employeeData.id, loading, {
                name: 'apps-employee-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-around align-center w-full">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(employeeData.employeeIds, employeeData.id) || loading"
              @click="
                goToPrevious(employeeData.employeeIds, employeeData.id, loading, {
                  name: 'apps-employee-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <v-avatar
              :color="employeeData.avatar ? '' : 'primary'"
              :class="employeeData.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4"
            >
              <v-img
                v-if="employeeData.avatar"
                :src="require(`@/assets/images/avatars/${employeeData.avatar}`)"
              ></v-img>
              <span v-else class="font-weight-semibold text-5xl">{{
                avatarText(`${employeeData.firstName} ${employeeData.lastName}`)
              }}</span>
            </v-avatar>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(employeeData.employeeIds, employeeData.id) || loading"
              @click="
                goToNext(employeeData.employeeIds, employeeData.id, loading, {
                  name: 'apps-employee-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>

          <span class="mb-2">{{ employeeData.firstName }} {{ employeeData.lastName }}</span>
          <v-chip
            label
            small
            color="primary"
            class="v-chip-light-bg primary--text font-weight-semibold text-capitalize ma-1 mb-2"
            v-text="statusText"
          />
          <span class="d-flex justify-center align-center flex-wrap">
            <v-chip
              v-for="(tag, index) in employeeData.tags"
              :key="index"
              label
              small
              color="info"
              outlined
              class="font-weight-semibold ma-1"
              v-text="tag.tag"
            />
          </span>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('firstName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ employeeData.firstName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('lastName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ employeeData.lastName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('email', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ employeeData.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('phoneNumber') }}:</span>
              <span class="text--secondary">{{ employeeData.phone }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('language') }}:</span>
              <span class="text--secondary">
                <v-chip
                  v-for="lang in languageTexts"
                  :key="lang.value"
                  small
                  label
                  color="secondary"
                  class="v-chip-light-bg secondary--text font-weight-medium text-capitalize ma-1"
                  v-text="lang.text"
                />
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('startWorkingDay') }}:</span>
              <span class="text--secondary">{{ employeeData.workingStartDay }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('martialStatus') }}:</span>
              <span class="text--secondary">{{ employeeData.maritalStatus }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('nationality') }}:</span>
              <span class="text--secondary">{{ employeeData.nationality }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium text-no-wrap me-2">{{ $t('kids') }}:</span>
              <span class="text--secondary">{{ employeeData.kids ? $t('yes') : $t('no') }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-row justify="center">
            <v-col v-if="$can(ACTIONS.DELETE, employeeData)" cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">{{
                        $t('confirmationModalUndoneQuestion')
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteEmployeeHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col cols="auto">
              <v-btn color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <employee-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :employee-data="employeeData"
        :language-choices="languageChoices"
        :status-choices="statusChoices"
      ></employee-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiPencilOutline, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { avatarText, kFormatter, formatDateToMonthShort, emptyValueFormatter } from '@core/utils/filter'
import { ref, onMounted, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import EmployeeBioEdit from './EmployeeBioEdit.vue'
import useEmployeeList from '../../employee-list/useEmployeeList'
import employeeUtils from '@/utils/employeeUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    EmployeeBioEdit,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { deleteEmployee } = useActions('staff', ['deleteEmployee'])
    const { employeeData } = useState('staff', ['employeeData'])

    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const deleteEmployeeHandler = async () => {
      await deleteEmployee(employeeData.value.id)
      await router.push({ name: 'apps-employee-list' })
    }

    const { resolveUserAccessVariant, resolveUserStatusVariant } = useEmployeeList()

    const {
      fetchLanguageChoices,
      fetchStatusChoices,

      languageChoices,
      statusChoices,
    } = employeeUtils()

    const languageTexts = computed(() => {
      return languageChoices.value.filter((lang) => employeeData.value.languages.includes(lang.value))
    })
    const statusText = computed(
      () => statusChoices.value.find((status) => status.value === employeeData.value.status)?.text,
    )

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    onMounted(async () => {
      await fetchStatusChoices()
      await fetchLanguageChoices()
    })

    return {
      resolveUserAccessVariant,
      resolveUserStatusVariant,
      avatarText,
      kFormatter,
      formatDateToMonthShort,
      emptyValueFormatter,

      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      isDeleteDialogOpen,
      deleteEmployeeHandler,

      languageTexts,
      statusText,

      employeeData,

      languageChoices,
      statusChoices,
      isBioDialogOpen,
      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
        mdiChevronLeft,
        mdiChevronRight,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
