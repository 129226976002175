<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('addresses') }}
      <v-btn small color="primary" class="me-3" @click.stop="isAddAddressDrawerVisible = true">
        <v-icon class="me-2" left>{{ icons.mdiPlus }}</v-icon>
        <span v-t="'addAddress'" />
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="tableColumns"
      :items="allEmployeeAddresses"
      :group-by="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES) ? 'group' : null"
      sort-by="dateStart"
      sort-desc
      :item-key="'id'"
      :hide-default-footer="true"
      :items-per-page="-1"
      disable-pagination
      disable-filtering
      :loading="loading"
      :mobile-breakpoint="0"
    >
      <template #[`top`]>
        <v-dialog v-model="isDeleteDialogVisible" max-width="650px">
          <v-card class="pa-sm-10 pa-3">
            <v-card-title class="justify-center text-h5"
              >{{ $t('delete') }}
              {{ itemToDelete.type === 'custom' ? itemToDelete.name : itemToDelete.roomName }}</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <v-btn v-t="'discard'" outlined class="me-3" :disabled="deleteLoading" @click="discardDelete" />
                  <v-btn
                    v-if="itemToDelete.type === 'custom'"
                    :loading="deleteLoading"
                    color="error"
                    @click="confirmDeleteCustomAddress"
                    >{{ $t('delete') }}</v-btn
                  >
                  <v-btn
                    v-else-if="itemToDelete.type === 'company'"
                    :loading="deleteLoading"
                    color="error"
                    @click="confirmDeleteAssignmentToCompanyAddress"
                    >{{ $t('delete') }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isUpdateDialogVisible" max-width="650px">
          <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-card-title v-t="'edit'" class="justify-center text-h5" />
            <v-card-text class="mt-5">
              <v-form
                v-show="addressToUpdate.type === 'custom'"
                ref="updateCustomAddressForm"
                class="multi-col-validation"
                :disabled="updateLoading"
                @submit.prevent="confirmUpdateCustomAddress"
              >
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="addressToUpdate.name"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('name')"
                      :placeholder="$t('name')"
                      :rules="[validators.required]"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      v-model="addressToUpdate.street"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('street')"
                      :placeholder="$t('street')"
                      maxlength="100"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.houseNumber"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('houseNumber')"
                      :placeholder="$t('houseNumber')"
                      maxlength="20"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.flatNumber"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('flatNumber')"
                      :placeholder="$t('flatNumber')"
                      maxlength="20"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="8" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.city"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('city')"
                      :placeholder="$t('city')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.zipCode"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('zipCode')"
                      :placeholder="$t('zipCode')"
                      maxlength="10"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.state"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('state')"
                      :placeholder="$t('state')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.mdAndUp ? 'pl-3' : '']">
                    <v-text-field
                      v-model="addressToUpdate.country"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('country')"
                      :placeholder="$t('country')"
                      maxlength="50"
                      class="mb-3"
                    ></v-text-field> </v-col
                ></v-row>
                <upgrade-alert-wrapper class="mb-3" :form="false">
                  <template #blocked-features>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-menu
                          close-on-content-click
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        >
                          <template v-slot:activator="activator">
                            <v-text-field
                              v-model="addressToUpdate.dateStart"
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                              :label="$t('accommodationDateStart')"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="activator.attrs"
                              hide-details="auto"
                              :rules="[
                                validators.dateRangeValidator(addressToUpdate.dateStart, addressToUpdate.dateEnd),
                              ]"
                              class="mb-3"
                              v-on="activator.on"
                            />
                          </template>
                          <v-date-picker
                            v-model="addressToUpdate.dateStart"
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-menu
                          :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          close-on-content-click
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="activator">
                            <v-text-field
                              v-model="addressToUpdate.dateEnd"
                              :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                              :label="$t('accommodationDateEnd')"
                              readonly
                              outlined
                              dense
                              clearable
                              v-bind="activator.attrs"
                              hide-details="auto"
                              :rules="[
                                validators.dateRangeValidator(addressToUpdate.dateStart, addressToUpdate.dateEnd),
                              ]"
                              v-on="activator.on"
                            />
                          </template>
                          <v-date-picker
                            v-model="addressToUpdate.dateEnd"
                            :first-day-of-week="1"
                            :locale="$i18n.locale"
                          />
                        </v-menu>
                      </v-col>
                    </v-row>
                  </template>
                </upgrade-alert-wrapper>

                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined color="secondary" class="me-3" @click.prevent="resetUpdateDialog" />
                    <v-btn color="primary" type="submit" :loading="updateLoading">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <v-form
                v-show="addressToUpdate.type === 'company'"
                ref="updateAssignmentToCompanyAddressForm"
                class="multi-col-validation"
                :disabled="updateLoading"
                @submit.prevent="confirmUpdateAssignmentToCompanyAddress"
              >
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="addressToUpdate.room"
                      :items="roomOccupantsChoices"
                      outlined
                      clearable
                      dense
                      hide-details="auto"
                      :label="$t('room')"
                      :placeholder="$t('rooms')"
                      :rules="[validators.nonEmptyValueValidator]"
                    >
                      <template v-if="addressToUpdate.room" #append-outer>
                        <v-fade-transition leave-absolute>
                          <v-icon color="primary" @click="goToRoomDetailsPage(addressToUpdate.room)">{{
                            icons.mdiOpenInNew
                          }}</v-icon>
                        </v-fade-transition>
                      </template>
                      <template #item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.text }}
                            <span
                              v-if="item.occupantsCount - item.demand === 0"
                              class="primary--text font-weight-medium text-capitalize ml-2"
                              >{{ item.occupantsCount }}/{{ item.demand }}</span
                            >
                            <span
                              v-if="item.occupantsCount - item.demand > 0"
                              class="success--text font-weight-medium text-capitalize ml-2"
                              >{{ item.occupantsCount }}/{{ item.demand }}</span
                            >
                            <span
                              v-if="item.occupantsCount - item.demand < 0"
                              class="error--text font-weight-medium text-capitalize ml-2"
                              >{{ item.occupantsCount }}/{{ item.demand }}</span
                            >
                          </v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <upgrade-alert-wrapper>
                      <template #blocked-features>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="addressToUpdate.dateStart"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('accommodationDateStart')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(addressToUpdate.dateStart, addressToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="addressToUpdate.dateStart"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="addressToUpdate.dateEnd"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('accommodationDateEnd')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(addressToUpdate.dateStart, addressToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="addressToUpdate.dateEnd"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                      </template>
                    </upgrade-alert-wrapper>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined color="secondary" class="me-3" @click.prevent="resetUpdateDialog" />
                    <v-btn color="primary" type="submit" :loading="updateLoading">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-navigation-drawer
          v-model="isAddAddressDrawerVisible"
          temporary
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
          app
        >
          <div class="drawer-header d-flex align-center">
            <span v-t="'addNewAddress'" class="font-weight-semibold text-base text--primary" />
            <v-spacer></v-spacer>
            <v-btn icon small @click="isAddAddressDrawerVisible = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-card-text class="pt-5 pb-0">
            <v-btn-toggle v-model="addAddressType" mandatory color="primary">
              <v-btn v-t="'customAddressOption'" class="text--primary" left outlined />
              <v-btn v-t="'companyAddressOption'" class="text--primary" right outlined />
            </v-btn-toggle>
          </v-card-text>
          <v-form v-show="addAddressType === 0" ref="addCustomAddressForm" @submit.prevent="confirmAddCustomAddress">
            <v-card-text>
              <v-subheader v-t="'customAddressData'" />
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="addCustomAddressData.name"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('name')"
                    :placeholder="$t('name')"
                    :rules="[validators.required]"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-text-field
                    v-model="addCustomAddressData.street"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('street')"
                    :placeholder="$t('street')"
                    maxlength="100"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.houseNumber"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('houseNumber')"
                    :placeholder="$t('houseNumber')"
                    maxlength="20"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.flatNumber"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('flatNumber')"
                    :placeholder="$t('flatNumber')"
                    maxlength="20"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="8" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.city"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('city')"
                    :placeholder="$t('city')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" :class="[$vuetify.breakpoint.smAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.zipCode"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('zipCode')"
                    :placeholder="$t('zipCode')"
                    maxlength="10"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.smAndUp ? 'pr-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.state"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('state')"
                    :placeholder="$t('state')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" :class="[$vuetify.breakpoint.mdAndUp ? 'pl-3' : '']">
                  <v-text-field
                    v-model="addCustomAddressData.country"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('country')"
                    :placeholder="$t('country')"
                    maxlength="50"
                    class="mb-3"
                  ></v-text-field> </v-col
              ></v-row>
              <upgrade-alert-wrapper class="mb-3" :form="false">
                <template #blocked-features>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-menu
                        close-on-content-click
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                      >
                        <template v-slot:activator="activator">
                          <v-text-field
                            v-model="addCustomAddressData.dateStart"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                            :label="$t('accommodationDateStart')"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="activator.attrs"
                            hide-details="auto"
                            :rules="[
                              validators.dateRangeValidator(
                                addCustomAddressData.dateStart,
                                addCustomAddressData.dateEnd,
                              ),
                            ]"
                            class="mb-3"
                            v-on="activator.on"
                          />
                        </template>
                        <v-date-picker
                          v-model="addCustomAddressData.dateStart"
                          :first-day-of-week="1"
                          :locale="$i18n.locale"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-menu
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        close-on-content-click
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="activator">
                          <v-text-field
                            v-model="addCustomAddressData.dateEnd"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                            :label="$t('accommodationDateEnd')"
                            readonly
                            outlined
                            dense
                            clearable
                            v-bind="activator.attrs"
                            hide-details="auto"
                            :rules="[
                              validators.dateRangeValidator(
                                addCustomAddressData.dateStart,
                                addCustomAddressData.dateEnd,
                              ),
                            ]"
                            v-on="activator.on"
                          />
                        </template>
                        <v-date-picker
                          v-model="addCustomAddressData.dateEnd"
                          :first-day-of-week="1"
                          :locale="$i18n.locale"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                </template>
              </upgrade-alert-wrapper>
            </v-card-text>
            <v-card-actions>
              <v-row class="overwritten-drawer-actions">
                <v-col cols="12" md="6">
                  <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                    {{ $t('add') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    v-t="'discard'"
                    color="secondary"
                    outlined
                    type="reset"
                    block
                    @click="resetAddNavigationDrawer"
                  />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
          <v-form
            v-show="addAddressType === 1"
            ref="assignToCompanyAddressForm"
            @submit.prevent="confirmAddCompanyAddress"
          >
            <v-card-text>
              <v-subheader v-t="'addressData'" />
              <v-autocomplete
                v-model="assignToCompanyAddressData.room"
                :items="roomOccupantsChoices"
                outlined
                clearable
                dense
                hide-details="auto"
                :label="$t('room')"
                :placeholder="$t('rooms')"
                :rules="[validators.nonEmptyValueValidator]"
                class="mb-3"
              >
                <template v-if="assignToCompanyAddressData.room" #append-outer>
                  <v-fade-transition leave-absolute>
                    <v-icon color="primary" @click="goToRoomDetailsPage(assignToCompanyAddressData.room)">{{
                      icons.mdiOpenInNew
                    }}</v-icon>
                  </v-fade-transition>
                </template>
                <template #item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ item.text }}
                      <span
                        v-if="item.occupantsCount - item.demand === 0"
                        class="primary--text font-weight-medium text-capitalize ml-2"
                        >{{ item.occupantsCount }}/{{ item.demand }}</span
                      >
                      <span
                        v-if="item.occupantsCount - item.demand > 0"
                        class="success--text font-weight-medium text-capitalize ml-2"
                        >{{ item.occupantsCount }}/{{ item.demand }}</span
                      >
                      <span
                        v-if="item.occupantsCount - item.demand < 0"
                        class="error--text font-weight-medium text-capitalize ml-2"
                        >{{ item.occupantsCount }}/{{ item.demand }}</span
                      >
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <upgrade-alert-wrapper :form="false" class="mb-3">
                <template #blocked-features>
                  <v-menu
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="assignToCompanyAddressData.dateStart"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('accommodationDateStart')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[
                          validators.dateRangeValidator(
                            assignToCompanyAddressData.dateStart,
                            assignToCompanyAddressData.dateEnd,
                          ),
                        ]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker
                      v-model="assignToCompanyAddressData.dateStart"
                      :first-day-of-week="1"
                      :locale="$i18n.locale"
                    />
                  </v-menu>
                  <v-menu
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="assignToCompanyAddressData.dateEnd"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('accommodationDateEnd')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[
                          validators.dateRangeValidator(
                            assignToCompanyAddressData.dateStart,
                            assignToCompanyAddressData.dateEnd,
                          ),
                        ]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker
                      v-model="assignToCompanyAddressData.dateEnd"
                      :first-day-of-week="1"
                      :locale="$i18n.locale"
                    />
                  </v-menu>
                </template>
              </upgrade-alert-wrapper>
            </v-card-text>
            <v-card-actions>
              <v-row class="overwritten-drawer-actions">
                <v-col cols="12" md="6">
                  <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                    {{ $t('add') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn
                    v-t="'discard'"
                    color="secondary"
                    outlined
                    type="reset"
                    block
                    @click="resetAddNavigationDrawer"
                  />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-navigation-drawer>
      </template>

      <template #[`header.name`]="{ header }"> {{ $t(header.text) }} / {{ $t('room') }} </template>

      <template #[`header.type`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.address`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateStart`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateEnd`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.actions`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-icon v-if="isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronUpCircleOutline }}</v-icon>
          <v-icon v-else-if="!isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronDownCircleOutline }}</v-icon>
          <span v-if="group === 'current'" v-t="'currentGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'future'" v-t="'futureGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'past'" v-t="'pastGroup'" class="font-weight-semibold" />
        </td>
      </template>

      <template #[`item.name`]="{ item }">
        <div class="d-flex align-center">
          <div
            v-if="item.type === 'custom'"
            class="d-flex flex-column ms-3 font-weight-semibold text-truncate text-decoration-none"
          >
            {{ item.name }}
          </div>
          <div v-else-if="item.type === 'company'" class="d-flex flex-column ms-3 cursor-pointer">
            <router-link
              :to="{ name: 'apps-house-room-view', params: { id: item.room } }"
              class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.roomName }}
            </router-link>
          </div>
        </div>
      </template>

      <template #[`item.type`]="{ item }">
        {{ item.type === 'company' ? $t('companyAddressOption') : $t('customAddressOption') }}
      </template>

      <template #[`item.address`]="{ item }">
        {{ emptyValueFormatter(item.address) }}
      </template>

      <template #[`item.dateStart`]="{ item }">
        {{ formatDateToMonthShort(item.dateStart, false) }}
      </template>

      <template #[`item.dateEnd`]="{ item }">
        {{ formatDateEndColumn(item) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="tooltipActivator">
              <v-btn
                icon
                small
                color="error"
                v-bind="tooltipActivator.attrs"
                v-on="tooltipActivator.on"
                @click="deleteItemHandler(item)"
              >
                <v-icon size="18">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'delete'" />
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" small v-bind="attrs" @click="updateAddressHandler(item)" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'edit'" />
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiChevronUpCircleOutline,
  mdiChevronDownCircleOutline,
  mdiTrashCanOutline,
  mdiOpenInNew,
} from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'

import { emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { required, dateRangeValidator, nonEmptyValueValidator } from '@core/utils/validation'
import _ from 'lodash'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

import assigneeTableUtils from '@/utils/assigneeTableUtils'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: { UpgradeAlertWrapper },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      createCustomAddress,
      updateCustomAddress,
      deleteCustomAddress,

      deleteOccupant,
      createOccupants,
      updateOccupantDetails,

      getRoomOccupantsChoices,
    } = useActions('staff', [
      'createCustomAddress',
      'updateCustomAddress',
      'deleteCustomAddress',

      'deleteOccupant',
      'createOccupants',
      'updateOccupantDetails',

      'getRoomOccupantsChoices',
    ])
    const { employeeData } = useState('staff', ['employeeData'])

    const allEmployeeAddresses = computed(() => {
      return [...employeeData.value.customAddress, ...employeeData.value.occupants]
    })

    const { router } = useRouter()

    const { commonTableColumns, formatDateEndColumn } = assigneeTableUtils()

    const tableColumns = [
      {
        text: 'name',
        value: 'name',
        sortable: false,
        groupable: false,
        class: 'ps-7 text-uppercase',
      },
      {
        text: 'type',
        value: 'type',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
      },
      {
        text: 'address',
        value: 'address',
        sortable: true,
        groupable: false,
        class: 'text-uppercase',
      },
      ...commonTableColumns,
    ]

    const goToRoomDetailsPage = (roomId) => {
      if (!roomId) return
      const route = router.resolve({ name: 'apps-house-room-view', params: { id: roomId } })
      window.open(route.href, '_blank')
    }

    // Add
    const roomOccupantsChoices = ref([])
    const addLoading = ref(false)
    const addAddressType = ref(0)
    const isAddAddressDrawerVisible = ref(false)

    const addCustomAddressForm = ref(null)
    const addCustomAddressData = ref({
      name: '',
      street: '',
      houseNumber: '',
      flatNumber: '',
      city: '',
      zipCode: '',
      state: '',
      country: '',
      dateStart: null,
      dateEnd: null,
    })

    const assignToCompanyAddressForm = ref(null)
    const assignToCompanyAddressData = ref({
      room: null,
      dateStart: null,
      dateEnd: null,
    })

    const resetAddNavigationDrawer = () => {
      isAddAddressDrawerVisible.value = false
      addCustomAddressForm.value.reset()
      assignToCompanyAddressForm.value.reset()
      addAddressType.value = 0
    }

    const confirmAddCustomAddress = async () => {
      const isFormValid = addCustomAddressForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true

      const { success } = await createCustomAddress({
        employee: employeeData.value.id,
        ...addCustomAddressData.value,
      })
      if (success) {
        emit('refetch-data')
        resetAddNavigationDrawer()
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      addLoading.value = false
    }

    const confirmAddCompanyAddress = async () => {
      const isFormValid = assignToCompanyAddressForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true

      const { success } = await createOccupants({
        employee: employeeData.value.id,
        ...assignToCompanyAddressData.value,
      })
      if (success) {
        emit('refetch-data')
        resetAddNavigationDrawer()
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      addLoading.value = false
    }

    // Delete
    const deleteLoading = ref(false)
    const itemToDelete = ref({})
    const isDeleteDialogVisible = ref(false)

    const deleteItemHandler = (item) => {
      itemToDelete.value = item
      isDeleteDialogVisible.value = true
    }
    const confirmDeleteCustomAddress = async () => {
      if (!itemToDelete.value) return

      deleteLoading.value = true

      const { success } = await deleteCustomAddress(itemToDelete.value.id)
      if (success) {
        emit('refetch-data')
        itemToDelete.value = {}
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const confirmDeleteAssignmentToCompanyAddress = async () => {
      if (!itemToDelete.value) return

      deleteLoading.value = true

      const { success } = await deleteOccupant({
        occupantId: itemToDelete.value.id,
      })
      if (success) {
        emit('refetch-data')
        itemToDelete.value = {}
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDelete.value = {}
      isDeleteDialogVisible.value = false
    }

    // Update
    const isUpdateDialogVisible = ref(false)
    const updateLoading = ref(false)
    const addressToUpdate = ref({})

    const updateCustomAddressForm = ref(null)
    const updateAssignmentToCompanyAddressForm = ref(null)

    const resetUpdateDialog = () => {
      isUpdateDialogVisible.value = false
      updateCustomAddressForm.value.reset()
      updateAssignmentToCompanyAddressForm.value.reset()
    }

    const updateAddressHandler = (address) => {
      addressToUpdate.value = _.cloneDeep(address)
      isUpdateDialogVisible.value = true
    }

    const confirmUpdateCustomAddress = async () => {
      const isFormValid = updateCustomAddressForm.value.validate()
      if (!isFormValid || !addressToUpdate.value) return

      updateLoading.value = true

      const { success } = await updateCustomAddress(addressToUpdate.value)
      if (success) {
        emit('refetch-data')
        resetUpdateDialog()
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      updateLoading.value = false
    }

    const confirmUpdateAssignmentToCompanyAddress = async () => {
      const isFormValid = updateAssignmentToCompanyAddressForm.value.validate()
      if (!isFormValid || !addressToUpdate.value) return

      updateLoading.value = true

      const { success } = await updateOccupantDetails({
        occupantData: {
          id: addressToUpdate.value.id,
          employee: employeeData.value.id,
          room: addressToUpdate.value.room,
          dateStart: addressToUpdate.value.dateStart,
          dateEnd: addressToUpdate.value.dateEnd,
        },
      })
      if (success) {
        emit('refetch-data')
        resetUpdateDialog()
        roomOccupantsChoices.value = await getRoomOccupantsChoices()
      }
      updateLoading.value = false
    }

    // ---------------

    onMounted(async () => {
      roomOccupantsChoices.value = await getRoomOccupantsChoices()
    })

    return {
      // Add
      confirmAddCustomAddress,
      addCustomAddressForm,
      addCustomAddressData,

      confirmAddCompanyAddress,
      assignToCompanyAddressForm,
      assignToCompanyAddressData,

      resetAddNavigationDrawer,
      addLoading,
      isAddAddressDrawerVisible,
      addAddressType,

      // Delete
      deleteItemHandler,
      confirmDeleteCustomAddress,
      confirmDeleteAssignmentToCompanyAddress,
      discardDelete,
      isDeleteDialogVisible,
      deleteLoading,
      itemToDelete,

      // Update
      updateAddressHandler,
      resetUpdateDialog,
      addressToUpdate,
      isUpdateDialogVisible,
      updateLoading,

      confirmUpdateCustomAddress,
      updateCustomAddressForm,

      confirmUpdateAssignmentToCompanyAddress,
      updateAssignmentToCompanyAddressForm,

      // ---------------
      formatDateEndColumn,
      goToRoomDetailsPage,

      employeeData,
      tableColumns,
      allEmployeeAddresses,
      roomOccupantsChoices,

      emptyValueFormatter,
      formatDateToMonthShort,

      validators: {
        required,
        dateRangeValidator,
        nonEmptyValueValidator,
      },

      icons: {
        mdiClose,
        mdiPlus,
        mdiPencilOutline,
        mdiChevronUpCircleOutline,
        mdiChevronDownCircleOutline,
        mdiTrashCanOutline,
        mdiOpenInNew,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
