<template>
  <div class="user-tab-security">
    <file-table
      :loading="loading"
      :files="localFiles"
      :file-types="fileTypes"
      handle-types-and-valid-until
      handle-reminders
      handle-document-generation
      :document-generator-data="{
        module: MODULE_EMPLOYEES,
        obj: employeeData,
        target: `${employeeData.firstName} ${employeeData.lastName}`,
        assignments: employeeData.assignedPositions,
        customAddresses: employeeData.customAddress,
        accommodations: employeeData.occupants,
      }"
      :upload-file-callback="uploadEmployeeFiles"
      :disable-delete="!$can(ACTIONS.DELETE, PRIVATE.STAFF.EMPLOYEES_FILES)"
      :delete-file-callback="deleteEmployeeFile"
      :update-file-callback="updateEmployeeFile"
      :file-target-object="{ employeeId: employeeData.id }"
      :reminder-target-object="{ employee: employeeData.id }"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import _ from 'lodash'
import employeeUtils from '@/utils/employeeUtils'
import router from '@/router'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: {
    FileTable,
  },
  setup() {
    const { uploadEmployeeFiles, getEmployeeDetails, deleteEmployeeFile, updateEmployeeFile } = useActions('staff', [
      'uploadEmployeeFiles',
      'getEmployeeDetails',
      'deleteEmployeeFile',
      'updateEmployeeFile',
    ])
    const { employeeData } = useState('staff', ['employeeData'])

    const {
      fetchFileTypes,

      fileTypes,
    } = employeeUtils()
    const { MODULE_EMPLOYEES } = documentTemplateUtils()

    const loading = ref(false)
    const localFiles = ref([])

    localFiles.value = _.cloneDeep(employeeData.value.files)

    const refetchData = async () => {
      loading.value = true
      await getEmployeeDetails(router.currentRoute.params.id)
      localFiles.value = _.cloneDeep(employeeData.value.files)
      await fetchFileTypes()
      loading.value = false
    }

    onMounted(async () => {
      await fetchFileTypes()
    })

    return {
      deleteEmployeeFile,
      updateEmployeeFile,
      uploadEmployeeFiles,
      refetchData,

      employeeData,

      fileTypes,
      localFiles,
      loading,

      MODULE_EMPLOYEES,

      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style scoped lang="scss"></style>
