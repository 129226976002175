var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-tab-security"},[_c('file-table',{attrs:{"loading":_vm.loading,"files":_vm.localFiles,"file-types":_vm.fileTypes,"handle-types-and-valid-until":"","handle-reminders":"","handle-document-generation":"","document-generator-data":{
      module: _vm.MODULE_EMPLOYEES,
      obj: _vm.employeeData,
      target: ((_vm.employeeData.firstName) + " " + (_vm.employeeData.lastName)),
      assignments: _vm.employeeData.assignedPositions,
      customAddresses: _vm.employeeData.customAddress,
      accommodations: _vm.employeeData.occupants,
    },"upload-file-callback":_vm.uploadEmployeeFiles,"disable-delete":!_vm.$can(_vm.ACTIONS.DELETE, _vm.PRIVATE.STAFF.EMPLOYEES_FILES),"delete-file-callback":_vm.deleteEmployeeFile,"update-file-callback":_vm.updateEmployeeFile,"file-target-object":{ employeeId: _vm.employeeData.id },"reminder-target-object":{ employee: _vm.employeeData.id }},on:{"refetch-data":_vm.refetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }