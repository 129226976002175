<template>
  <reminder-table resource-name="employee" :resource-id="employeeData.id" @refetch-data="emit('refetch-data')" />
</template>

<script>
import { useState } from 'vuex-composition-helpers'
import ReminderTable from '@/components/reminders-table/ReminderTable.vue'

export default {
  components: { ReminderTable },
  setup(props, { emit }) {
    const { employeeData } = useState('staff', ['employeeData'])

    return {
      emit,
      employeeData,
    }
  },
}
</script>
