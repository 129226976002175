import assigneeTableUtils from '@/utils/assigneeTableUtils'

export default function usePositionAssigneesTable() {
  const { commonTableColumns, formatDateEndColumn } = assigneeTableUtils()
  const tableColumns = [
    {
      text: 'position',
      value: 'positionName',
      sortable: true,
      groupable: false,
      class: 'ps-7 text-uppercase',
    },
    { text: 'project', value: 'projectName', sortable: true, groupable: false, class: 'text-uppercase' },
    { text: 'client', value: 'clientName', sortable: true, groupable: false, class: 'text-uppercase' },
    ...commonTableColumns,
  ]

  return {
    tableColumns,
    formatDateEndColumn,
  }
}
