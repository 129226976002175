import { getCurrentInstance, ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function employeeUtils() {
  const vm = getCurrentInstance().proxy

  const CUSTOM_ADDRESS_OPTION = 'custom-address'

  const availableOccupants = ref([])
  const languageChoices = ref([])
  const statusChoices = ref([])
  const assigneePositionChoices = ref([])
  const tagsChoices = ref([])
  const fileTypes = ref([])

  const { getCandidateTags } = useActions('company', ['getCandidateTags'])
  const {
    getLanguagesOptions,
    getEmployeeStatusChoices,
    getAvailableAssigneesChoices,
    getAvailableOccupantsByRoomChoices,
    getEmployeeFileTypes,
  } = useActions('staff', [
    'getLanguagesOptions',
    'getEmployeeStatusChoices',
    'getAvailableAssigneesChoices',
    'getAvailableOccupantsByRoomChoices',
    'getEmployeeFileTypes',
  ])

  const getOccupantValue = (occupantRoom) => {
    return occupantRoom ?? CUSTOM_ADDRESS_OPTION
  }

  const updateOccupantValue = (event) => {
    if (event === CUSTOM_ADDRESS_OPTION) {
      return null
    }

    return event
  }

  const arrangeAvailableOccupantsByRoomItems = async () => {
    const fetchedAvailableOccupants = await getAvailableOccupantsByRoomChoices()

    const assignedOccupants = []

    const notAssignedOccupantsWithStatus = {}
    const notAssignedOccupantsWithoutStatus = []
    fetchedAvailableOccupants.forEach((occupant) => {
      if (occupant.disabled) {
        assignedOccupants.push(occupant)
      } else if (occupant.status !== undefined) {
        if (!Object.hasOwnProperty.bind(notAssignedOccupantsWithStatus)(occupant.status)) {
          notAssignedOccupantsWithStatus[occupant.status] = [occupant]
        } else {
          notAssignedOccupantsWithStatus[occupant.status].push(occupant)
        }
      } else {
        notAssignedOccupantsWithoutStatus.push(occupant)
      }
    })

    const mappedNotAssignedOccupants = []
    Object.entries(notAssignedOccupantsWithStatus).forEach(([status, occupants]) => {
      mappedNotAssignedOccupants.push(
        {
          header: status,
        },
        ...occupants,
      )
    })

    availableOccupants.value = [
      { text: vm.$t('customAddress'), value: CUSTOM_ADDRESS_OPTION },
      ...mappedNotAssignedOccupants,
      { header: vm.$t('otherAvailableOccupants') },
      ...notAssignedOccupantsWithoutStatus,
      { divider: true },
      { header: vm.$t('takenOccupants') },
      ...assignedOccupants,
    ]
  }

  const fetchLanguageChoices = async () => {
    languageChoices.value = await getLanguagesOptions()
  }

  const fetchStatusChoices = async () => {
    statusChoices.value = await getEmployeeStatusChoices()
  }

  const fetchAssigneePositionChoices = async () => {
    assigneePositionChoices.value = await getAvailableAssigneesChoices()
  }

  const fetchTagChoices = async () => {
    tagsChoices.value = await getCandidateTags()
  }

  const fetchFileTypes = async () => {
    fileTypes.value = await getEmployeeFileTypes()
  }

  return {
    getOccupantValue,
    updateOccupantValue,
    arrangeAvailableOccupantsByRoomItems,
    fetchLanguageChoices,
    fetchStatusChoices,
    fetchAssigneePositionChoices,
    fetchTagChoices,
    fetchFileTypes,

    CUSTOM_ADDRESS_OPTION,
    availableOccupants,
    languageChoices,
    statusChoices,
    assigneePositionChoices,
    tagsChoices,
    fileTypes,
  }
}
