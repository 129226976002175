<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> {{ $t('editEmployeeInformation') }} </v-card-title>

      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="employeeDataLocal.firstName"
                outlined
                dense
                maxlength="100"
                :label="$t('firstNameRequired')"
                :placeholder="$t('firstName', { postfix: '' })"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="employeeDataLocal.lastName"
                outlined
                dense
                maxlength="100"
                :label="$t('lastNameRequired')"
                :placeholder="$t('lastName', { postfix: '' })"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-autocomplete
                v-model="employeeDataLocal.status"
                :label="$t('status', { postfix: '' })"
                :placeholder="$t('status', { postfix: '' })"
                :items="statusChoices"
                outlined
                dense
                clearable
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-combobox
                v-model="employeeDataLocal.tags"
                :items="tagsChoices"
                :label="$t('tag')"
                :placeholder="$t('tag')"
                item-text="tag"
                item-value="id"
                multiple
                outlined
                dense
                hide-details
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-bind="attrs"
                    :input-value="selected"
                    small
                    label
                    color="info"
                    outlined
                    class="font-weight-semibold disable-min-height--chip mt-1"
                  >
                    <span class="pr-3">{{ item === Object(item) ? item.tag : item }}</span>
                    <v-icon x-small @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-combobox></v-col
            >

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="employeeDataLocal.phone"
                outlined
                dense
                :label="$t('phoneNumber')"
                :placeholder="$t('phoneNumber')"
                maxlength="31"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-autocomplete
                v-model="employeeDataLocal.languages"
                :label="$t('language')"
                :placeholder="$t('language')"
                :items="languageChoices"
                outlined
                dense
                multiple
                hide-details
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="employeeDataLocal.workingStartDay"
                    :label="$t('startWorkingDay')"
                    :placeholder="$t('startWorkingDay')"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="employeeDataLocal.workingStartDay"
                  :first-day-of-week="1"
                  :locale="$i18n.locale"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="employeeDataLocal.maritalStatus"
                outlined
                dense
                maxlength="100"
                :label="$t('martialStatus')"
                :placeholder="$t('martialStatus')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="employeeDataLocal.nationality"
                outlined
                dense
                maxlength="100"
                :label="$t('nationality')"
                :placeholder="$t('nationality')"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-switch v-model="employeeDataLocal.kids" :label="$t('kids')" class="mt-0" />
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn outlined color="secondary" class="me-3" @click.prevent="$emit('update:is-bio-dialog-open', false)">
                {{ $t('discard') }}
              </v-btn>
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed, onMounted } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'
import { mdiOpenInNew } from '@mdi/js'
import employeeUtils from '@/utils/employeeUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    employeeData: {
      type: Object,
      required: true,
    },
    languageChoices: {
      type: Array,
      required: true,
    },
    statusChoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const employeeDataLocal = ref({})

    employeeDataLocal.value = JSON.parse(JSON.stringify(props.employeeData))

    const { updateEmployee } = useActions('staff', ['updateEmployee'])

    const {
      fetchTagChoices,

      tagsChoices,
    } = employeeUtils()

    // on form submit
    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const payload = {
        user: {
          firstName: employeeDataLocal.value.firstName,
          lastName: employeeDataLocal.value.lastName,
          phone: employeeDataLocal.value.phone,
        },
        workingStartDay: employeeDataLocal.value.workingStartDay,
        nationality: employeeDataLocal.value.nationality,
        maritalStatus: employeeDataLocal.value.maritalStatus,
        kids: employeeDataLocal.value.kids,
        status: employeeDataLocal.value.status,
        languages: employeeDataLocal.value.languages,
        tags: employeeDataLocal.value.tags.map((tag) => {
          if (_.isObject(tag)) return tag

          return { tag }
        }),
      }
      const { success } = await updateEmployee({ id: employeeDataLocal.value.id, payload })
      await fetchTagChoices()
      loading.value = false
      if (success) {
        emit('update:is-bio-dialog-open', false)
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        employeeDataLocal.value = JSON.parse(JSON.stringify(props.employeeData))
      },
    )

    const areDataChanged = computed(() => {
      return (
        employeeDataLocal.value.firstName !== props.employeeData.firstName ||
        employeeDataLocal.value.lastName !== props.employeeData.lastName ||
        employeeDataLocal.value.workingStartDay !== props.employeeData.workingStartDay ||
        employeeDataLocal.value.phone !== props.employeeData.phone ||
        employeeDataLocal.value.nationality !== props.employeeData.nationality ||
        employeeDataLocal.value.maritalStatus !== props.employeeData.maritalStatus ||
        employeeDataLocal.value.kids !== props.employeeData.kids ||
        employeeDataLocal.value.status !== props.employeeData.status ||
        !_.isEmpty(_.differenceWith(employeeDataLocal.value.languages, props.employeeData.languages, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.employeeData.languages, employeeDataLocal.value.languages, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(employeeDataLocal.value.tags, props.employeeData.tags, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(props.employeeData.tags, employeeDataLocal.value.tags, _.isEqual))
      )
    })

    onMounted(async () => {
      await fetchTagChoices()
    })

    return {
      handleFormSubmit,

      valid,
      loading,
      bioEditForm,
      employeeDataLocal,
      areDataChanged,
      tagsChoices,

      validators: {
        required,
      },

      icons: {
        mdiOpenInNew,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
